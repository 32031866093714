.index {
    display: flex;
    width: 100vw;
    align-items: end;
    flex-flow: column;
}

.offer h1, .blockHeader {
    color: white;
}

.offer h1 {
    display: inline-block;
    margin-bottom: 18px;
    font-size: 84px;
}

.offer h1 p:first-child {
    margin-right: 127px;
}

.offer h1 p:last-child {
    text-align: end;
}

.getBlock h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 36px;
    font-weight: 700;
    
}

.getBlock h2:first-letter {
    letter-spacing: 1px;
}

.getForm {
    max-width: 525px;
    margin-left: auto;
    margin-bottom: 20px;
    padding: 61px 68px;
    border-radius: 20px;
    background-color: white;
}

.getForm h2 {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 700;
    text-align: left;
}

.getForm li{
    margin: 0 0 20px 27px;
    list-style: url('../../public/images/big_daw.svg');
    font-size: 20px;
    font-weight: 400;
}

.formButton {
    width: 100%;
    margin-top: 8px;
    padding: 20px 0;
    border-radius: 16px;
    background-color: #232897;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

button:hover {
    background-color: #484ebe;
    cursor: pointer;
}

.formButton:active {
    background-color: #5E64DB;
}


/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .offer h1 {
        font-size: 64px;
    }

    .getBlock h2 {
        font-size: 28px;
    }

    .getForm {
        max-width: 468px;
        padding: 44px 39px;
    }

    .getForm h2 {
        font-size: 28px;
    }

    .getForm li {
        margin-bottom: 20px;
        font-size: 18px;
    }
    
}
/* Medium-Little */
@media (max-width: 1366px) or (max-height: 820px) {
    .offer h1 {
        font-size: 46px;
    }

    .getBlock h2 {
        margin-bottom: 30px;
        font-size: 24px;
    }

    .getForm {
        max-width: 468px;
        padding: 40px 36px;
    }

    .getForm h2 {
        margin-bottom: 30px;
        font-size: 24px;
    }

    .getForm li {
        margin-bottom: 20px;
        font-size: 16px;
        list-style: url('../../public/images/little_daw.svg');
    }
}
/* Little */
@media (max-width: 666px) or (max-height: 680px) {
    .offer h1 {
        font-size: 44px;
    }
    .offer h1 p:first-child {
        margin-right: 78px;
    }
    .offer h1 p:last-child {
        margin-right: 24px;
    }
    .getBlock h2 {
        margin-bottom: 25px;
        font-size: 20px;
    }
    .getForm {
        padding: 28px 32px;
        max-width: 352px;
    }
    .getForm h2 {
        margin-bottom: 16px;
        text-align: start;
    }
    .getForm li {
        margin: 0 0 14px 27px;
        font-size: 13px;
    }
    .getForm li::marker {
        margin-bottom: -5px;
    } 
    .formButton {
        padding: 16px 0;
        margin-top: 2px;
        font-size: 14px;
    }   
}
/* Mobile */
@media (max-width: 570px) {
    .index {
        align-items: start;
    }
    /* main {
        align-items: start;
    } */
    .offer h1 {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
    }
    .offer h1 p {
        display: inline;
        font-size: 48px;
    }
    .offer h1 p:first-child {
        margin: 0;
    }
    .offer h1 p:last-child {
        margin-right: 0;
        text-align: start;
    }  
    .getBlock h2.blockHeader {
        display: none;
    }
    .getForm {
        max-width: 404px;
        padding: 32px 28px;
    }
    .getForm h2 {
        font-size: 24px;
    }
    .getForm li {
        font-size: 16px;
    }
}

/* Mobile-Little  */
@media (max-width: 500px) {
    .offer h1 p {
        font-size: 38px;
    }
    .getForm h2 {
        font-size: 20px;
    }
}