.page {
    min-height: 100vh;
    background-attachment: fixed;
    background-image: 
    url("../../public/images/buildings.webp"),
    url("../../public/images/gradient.jpeg");
    background-repeat: repeat-x, no-repeat;
    background-position-y: bottom;
    background-size: 322vh 89vh, cover;
    background-position-x: -30vh, left;
}
/* MEDIA */
/* Very narrow */
.page.extraNarrow {
    background-position-x: -42vh, left;
    background-size: 322vh 89vh, cover;
}  
/* Narrow (1024/768) */
.page.narrow {
    background-size: 322vh 89vh, cover;
    background-position-x: -35vh, left;
}
/* Wide (aspect 16/9) */
.page.wide {
    background-size: 322vh 89vh, cover;
    background-position-x: -30vh, left;
}   
/* Very wide */
.page.extraWide {
    background-size: 340vh 94vh, cover;
    background-position-x: -25vh, left;
}

@media (max-width: 1060px) {
    #root .page {
        background-position-x: -42vh, left;
    }
}
/* Pad */
@media (max-width: 850px) {
    #root .page {
        background-position-x: -48vh, left;
        background-size: 271vh 75vh, cover; 
    } 
}
/* Mobile low */
@media (max-width: 600px) {
    #root .page {
        background-position-x: -52vh, center;
        background-size: 271vh 75vh, cover; 
    } 
}
/* Mobile hight */
@media (max-width: 600px) and (min-height: 870px) {
    #root .page {
        background-size: 500vw 138vw, cover;
        background-position-x: -88vw, center;
    } 
}
/* Mobile hight */
/* @media (max-width: 600px) and (min-height: 830px) {
    #root .page {
        background-size: 2600px 720px, cover;
        background-position-x: -450px, center;
    } 
} */