header nav ul {
    padding: 45px 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    color: white;
}

nav li {
    margin-left: 7px;
}

.logo {
    margin: 0 auto 0 0;
}

.logo a {
    display: inline-block;
}

.firmNameLogo {
    width: 143px;
}

.agencyLogo {
    width: 102px;
    margin: 0 0 8px 7px;
}

.phone {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.numberPhone {
    display: flex;
    flex-direction: row;
    margin-right:14px;
    font-weight: 400;
    font-size: 24px;
}

.numberPhone p {
    margin-left: 7px;
}
.numberPhone p:last-child {
    font-weight: 700;
}
.requestPhone {
    padding-right: 50px;
    max-width: 100%;
    font-size: 12px;
}

.callButton {
    padding: 24px 32px;
    border-radius: 16px;
    background-color: white;
    color: #232897;
    font-size: 20px;
    font-weight: 700;
}

.callButton:hover {
    background-color: #d2eaf7;
    cursor: pointer;
}

.callButton:active {
    background-color: #C4E0EF;
}

/* MEDIA */

/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .callButton {
        font-size: 20px;
    }
}
/* Medium-Little */
@media (max-width: 1366px) or (max-height: 820px) {
    header nav ul {
        padding-bottom: 39px;
    }
    .firmNameLogo {
        width: 120px;
    }
    .agencyLogo {
        margin-bottom: 5px;
        width: 90px;
    }
    .numberPhone p {
        font-size: 22px;
        margin-left: 5px;
        font-weight: 400;
    }
    .requestPhone {
        padding-right: 25px;
        font-size: 13px;
    }
    .callButton {
        padding: 16px 32px;
        font-size: 16px;
    }
}
/* Little */
@media (max-width: 1066px) or (max-height: 700px) {
    .firmNameLogo {
        width: 107px;
    }
    .agencyLogo {
        margin-bottom: 5px;
        width: 77px;
    }
    .numberPhone p {
        margin-left: 5px;
    }
    .requestPhone {
        padding-right: 25px;
        font-size: 11px;
    }width
    .callButton {
        padding: 16px 32px;
        font-size: 14px;
    }
}
/* Mobile */
@media (max-width: 990px) {
    header nav ul{
        padding: 31px 0;
    }
    .firmNameLogo {
        width: 79px;
    }
    .agencyLogo {
        margin: 0 0 4px 2px;
        width: 56px;
    }
    .phone {
        display: none;
    }

    .callButton {
        padding: 20px 32px;
        font-size: 14px;
    }

    .getBlock h2.blockHeader {
        display: none;
    }
}

/* Little Mobile */
@media (max-width: 450px) {
    .callButton {
        padding: 16px 16px;
    }
}