header, .backConfidence {
    padding: 0 200px 0 200px;
}

main {
    flex: 1 1 auto;
    display: flex;
}

body {
    min-height: 100vh;
}

.backConfidence {
    flex: 1 1 auto;
    background: white;
}
.confidence {
    max-width: 972px;
}
.confidence h1 {
    padding: 86px 0 28px 0;
    font-size: 40px;
}
.confidence ol { 
    counter-reset: item 
}
.confidence li { 
    display: block 
}
.confidence ol li:before { 
    content: counters(item, ".") ". "; counter-increment: item;  
}
.confidence ul li {
    display: list-item;
    list-style: disc;
    margin-left: 30px;
}
.confidence ul li:before {
    content: none;
}

.confidence h2 {
    display: inline;
}
.confidence ol { 
    padding: 14px 0;
}
.confidence li h2, .confidence li::before{
    font-size: 24px;
}

.confidence li, .confidence li p, .confidence li li::before {
    font-size: 18px;
}

.confidence p {
    display: inline;
}

.confidence a {
    color: black;
}

/* MEDIA */

/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    header, .backConfidence {
        padding: 0 120px;
    }
    .confidence {
        max-width: 972px;
    }
    .confidence h1 {
        padding: 54px 0 20px 0;
        font-size: 32px;
    }
    .confidence li h2, .confidence li::before {
        font-size: 18px;
    }
    .confidence li, .confidence li p, .confidence li li::before {
        font-size: 14px;
    }
}
/* Little */
@media (max-width: 1366px) or (max-height: 810px) {
    .confidence {
        max-width: 782px;
    }
    .confidence h1 {
        padding: 69px 0 20px 0;
    }
}
/* Mobile */
@media (max-width: 970px) {
    header, .backConfidence {
        padding: 0 40px;
    }
    .confidence h1 {
        padding: 52px 0 24px 0;
        font-size: 28px;
    }
    .confidence li h2, .confidence li::before {
        font-size: 16px;
    }
    .confidence li, .confidence li p, .confidence li li::before {
        font-size: 12px;
    }
}
/* Little Mobile */
@media (max-width: 410px) {
    .backConfidence {
        padding: 0 20px;
    }
}