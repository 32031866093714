#offerForm {
    position: relative;
    z-index: 1000;
    margin: auto;
    max-width: 600px;
    padding: 88px 92px 59px 92px;
    border-radius: 40px;
    background-color: white;
    animation: openForm 0.7s;
}

#offerForm h2 {
    margin-bottom: 28px; 
    color: black;
    font-size: 32px;
    font-weight: 700;
    text-align: left;
}

#offerForm input {
    width: calc(100% - 64px);
    max-width: 600px;
    margin-bottom: 20px;
    padding: 20px 32px;
    border: black solid 1px;
    border-radius: 16px;
    font-size: 20px;
}

#offerForm input::placeholder {
    color: black;
}

#offerForm .formButton {
    max-width: 600px;
}

.confidential {
    margin-top: 16px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 21, 73, .6);
}

.confidential a {
    color:  rgba(0, 21, 73, .6);
}

#backgroundDark {
    box-sizing: border-box;
    position: fixed;
    display: flex;
    flex-flow: row;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100%;
    background-color: rgba(0, 0, 0, .6);
}

#backgroundDark:before {
    min-height: 100%;
}

#exitButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .0);
}
#exitButton:hover {
    background-color: rgba(0, 0, 0, .1);
}
#exitButton:active {
    background-color: rgba(0, 0, 0, .2);
}
#exitBackground {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.error {
    display: none;
    position: absolute;
    top: 60%;
    left: 2%;
}

.nameBlock, .phoneBlock {
    position: relative;
}
.nameBlock .errorTriangle,
.phoneBlock .errorTriangle {
    content: ''; 
    position: absolute;
    left: 30px; bottom: 25px;
    border: 8px solid transparent;
    border-bottom: 15px solid #D9D9D9;
} 
.nameBlock:hover .error, .phoneBlock:hover .error {
    display: inline;
    padding: 6px;
    border-radius: 4px;
    background-color: #D9D9D9;
    color: crimson;
}
#name:focus+.error, #phone:focus+.error {
    display: none;
}

#offerForm .errorInput {
    border-color: crimson;
}

/* SENDMESSAGE */
#sendMessage {
    position: relative;
    z-index: 1000;
    margin: auto;
    /* max-width: 600px; */
    padding: 102px 76px 102px 76px;
    border-radius: 20px;
    background-color: white;
    animation: openForm 0.7s;
}
#sendMessage h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}
.sendImages {
    position: relative;
    margin-bottom: 52px;
    text-align: center;
}
#littleStar-bottom, #littleStar-top {
    width: 27px;
}
#littleStar-bottom {
    position: absolute;
    bottom: 15%;
    left: 32%;
}
#littleStar-top {
    position: absolute;
    top: 10%;
    left: 66%;
}
#bigStar {
    position: absolute;
    top: -18%;
    left: 60%;
}

@keyframes openForm {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    #offerForm {
        padding: 68px 72px 39px 72px;
        border-radius: 30px;
    }
    #exitButton {
        top: 1rem;
        right: 1rem;
    }
}
/* Little */
@media (max-width: 1366px) or (max-height: 810px) {
    #offerForm {
        padding: 68px 72px 39px 72px;
        border-radius: 30px;
    }
}
/* Mobile */
@media (max-width: 970px) {
    #offerForm {
        box-sizing: content-box;
        padding: 28px 64px;
        min-width: 170px;
        border-radius: 20px;
        padding: 64px 28px;
    }
    #exitButton {
        top: 1rem;
        right: 1rem;
    }

    #offerForm h2 {
        content: "Получите медиаплан для продвижения автобизнеса";
        font-size: 24px;
    }

    #offerForm input {
        max-width: calc(100% - 64px);
        padding: 20px 32px;

    }
}